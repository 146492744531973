<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <b-card class="">
          <h4 class="text-primary">
            <feather-icon icon="PlusSquareIcon" size="20"/>
            Add category
          </h4>

          <b-row>
            <b-media class="my-2 col-12">
              <validation-observer
                  ref="refFormObserver"
              >
                <b-row>
                  <b-col cols="12" md="6">
                    <language-picker
                        @getLanguage="setLanguageId"
                    ></language-picker>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Title"
                        label-for="blog-content"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="blog-content"
                            v-model="category.name"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <template>
                      <p class="my-1">Cover</p>
                      <b-avatar
                          ref="previewEl"
                          :src="base64ImageSrc"
                          class="cursor-pointer"
                          rounded
                          size="300px"
                          @click.native="$refs.refInputEl.click()"
                      >
                        <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
                      </b-avatar>
                    </template>
                    <b-button
                        class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none"
                        variant="warning"
                        @click="$refs.refInputEl.click()"
                    >
                      <input
                          ref="refInputEl"
                          accept=".jpg,.jpeg,.png"
                          class="d-none"
                          type="file"
                          @input="setCover($event)"
                      >
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="6">
                    <template>
                      <p class="my-1">Item picture</p>
                      <b-avatar
                          ref="previewEl2"
                          :src="base64ImageSrc2"
                          class="cursor-pointer"
                          rounded
                          size="150px"
                          @click.native="$refs.refInputEl2.click()"
                      >
                        <feather-icon v-if="base64ImageSrc2 === null" icon="PlusSquareIcon" size="100"/>
                      </b-avatar>
                    </template>
                    <b-button
                        class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none"
                        variant="warning"
                        @click="$refs.refInputEl2.click()"
                    >
                      <input
                          ref="refInputEl2"
                          accept=".jpg,.jpeg,.png"
                          class="d-none"
                          type="file"
                          @input="setCover2($event)"
                      >
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-button
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        class="mt-2 mb-sm-0 mr-0 col-12 col-md-5"
                        variant="primary"
                        @click="addCategory"
                    >
                      Add
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-media>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <h4 class="m-2">Categories list</h4>
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="pageData"
              :sort-by.sync="sortBy"
              bordered
              class="position-relative"
              empty-text="Nothing found!"
              primary-key="id"
              responsive
              show-empty
              striped
          >
            <template #cell(id)="data">
              <a target="_blank">
                <b-avatar :src="data.item.productCategoryFile" size="100" square/>
                <span class="text-muted ml-2">@{{ data.item.id }}</span>
              </a>
            </template>

            <template #cell(operation)="data">
              <div class="d-flex align-items-center justify-content-start">
                <add-edit-delete-button
                    :data="generateObjectForAddEditRemoveButton(data.item)"
                    :languageList="languageList"
                    :isBlogCategory="true"
                    :handelWithModal="true"
                    :routerEditName="'pages-posts-categories-edit'"
                    @renderDeleteModal="renderDeleteModal"
                    @renderCreateModal="renderCreateModal"
                    @renderEditModal="renderEditModal"
                ></add-edit-delete-button>
              </div>
            </template>

            <template #cell(sub)="data">
              <router-link :to="`/apps/categories/sub/${data.item.id}`">
                <feather-icon
                    size="20"
                    icon="LogInIcon"
                    style="cursor: pointer"
                    class="text-primary"
                />
              </router-link>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                  class="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap"
                  cols="12"
                  sm="12"
              >
                <b-pagination
                    v-model="currentPage"
                    :per-page="count"
                    :total-rows="totalCount"
                    class="mb-2 mb-md-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>

                <b-col
                    class="d-flex align-items-center justify-content-start justify-content-md-end mb-2 mb-md-0"
                    cols="12"
                    md="2"
                >
                  <label>Count</label>
                  <v-select
                      v-model="count"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      class="per-page-selector d-inline-block mx-50 w-50"
                  />
                </b-col>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
        id="modal-create"
        title="Add category with other language"
        cancelTitle="Cancel"
        centered
        ok-title="Add"
        no-close-on-backdrop
        @cancel="closeCreateModal"
        @ok="addCategory"
    >
      <b-form-group
          class="mt-2"
          label="title"
      >
        <b-form-input
            v-model="category.name"
        ></b-form-input>
      </b-form-group>

      <div class="relative">
        <b-avatar
            :src="base64ImageSrc"
            class="cursor-pointer w-100"
            rounded
            size="300px"
            @click.native="clickOnInput(category.mainId)"
        >
        </b-avatar>
        <b-button
            class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 absolute bottom-0 left-0"
            type="button"
            variant="warning"
            @click="clickOnInput(category.mainId)"
        >
          Edit cover
          <input
              :id="category.mainId"
              ref="refInputEl"
              accept=".jpg,.jpeg,.png"
              class="d-none"
              type="file"
              @input="setCover($event)"
          >
        </b-button>
      </div>

      <div class="relative my-1">
        <b-avatar
            :src="base64ImageSrc2"
            class="cursor-pointer"
            rounded
            size="100px"
            @click.native="clickOnInput(`${category.mainId}'2'`)"
        >
        </b-avatar>
        <b-button
            class="mt-2 mb-sm-0 mr-2 col-12 col-md-3 absolute bottom-0 left-0 mx-md-1"
            type="button"
            variant="warning"
            @click="clickOnInput(`${category.mainId}'2'`)"
        >
          Edit item picture
          <input
              :id="`${category.mainId}'2'`"
              ref="refInputEl2"
              accept=".jpg,.jpeg,.png"
              class="d-none"
              type="file"
              @input="setCover2($event)"
          >
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="selectedToEdit.id"
        title="Edit category"
        id="modal-edit"
        cancelTitle="Cancel"
        centered
        ok-title="Edit"
        no-close-on-backdrop
        @cancel="closeEditModal"
        @ok="updateData()"
    >
      <b-form-group
          class="mt-2"
          label="title"
      >
        <b-form-input
            v-model="selectedToEdit.name"
        ></b-form-input>
      </b-form-group>

      <div class="relative">
        <b-avatar
            :src="modalCoverPic"
            class="cursor-pointer w-100"
            rounded
            size="300px"
            @click.native="clickOnInput(selectedToEdit.id)"
        >
        </b-avatar>
        <b-button
            class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 absolute bottom-0 left-0"
            type="button"
            variant="warning"
            @click="clickOnInput(selectedToEdit.id)"
        >
          Edit cover
          <input
              :id="selectedToEdit.id"
              ref="productCategoryFile"
              accept=".jpg,.jpeg,.png"
              class="d-none"
              type="file"
              @input="setModalCover($event)"
          >
        </b-button>
      </div>

      <div class="relative my-1">
        <b-avatar
            :src="modalCoverPic2"
            class="cursor-pointer"
            rounded
            size="100px"
            @click.native="clickOnInput(selectedToEdit.id)"
        >
        </b-avatar>
        <b-button
            class="mt-2 mb-sm-0 mr-2 col-12 col-md-3 absolute bottom-0 left-0 mx-md-1"
            type="button"
            variant="warning"
            @click="clickOnInput(`${selectedToEdit.id}'2'`)"
        >
          Edit item picture
          <input
              :id="`${selectedToEdit.id}'2'`"
              ref="productCategoryItemFile"
              accept=".jpg,.jpeg,.png"
              class="d-none"
              type="file"
              @input="setModalCover2($event)"
          >
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        @ok="deleteData()"
    >
      <span>Are you sure?</span>
    </b-modal>

  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {
  ProductCategoriesCreate,
  ProductCategoriesDelete, ProductCategoriesGet,
  ProductCategoriesGetAll, ProductCategoriesGetBy,
  ProductCategoriesUpdate
} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";

export default {
  title: 'Product categories',
  data() {
    return {
      required,
      languageList:[],
      overlay: false,
      pageData: [],
      base64ImageSrc: null,
      base64ImageSrc2: null,
      category: {
        parentId: '',
        languageId: null,
        mainId: null,
        name: null,
        color: null,
      },
      perPageOptions: [10, 20, 30, 40, 50],
      currentPage: 1,
      sortBy: '',
      count: 10,
      totalCount: null,
      selectedToDelete: null,
      selectedToEdit: {},
      myTableColumns: [
        {
          key: 'id',
          label: 'Id & Cover',
          sortable: true
        },
        {
          key: 'name',
          label: 'Title',
          sortable: true
        },
        {
          key: 'sub',
          label: 'Sub Categories'
        },
        {
          key: 'operation',
          label: 'Operation'
        },
      ],
      selectedCategoryId: null,
      modalCoverPic:null,
      modalCoverPic2:null,
    }
  },
  async created() {
    await this.getData();
    await this.getAllLanguages();
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getData();
    },
    count: function (nv, ov) {
      this.getData();
    },
  },
  methods: {
    async getData() {
      let _this = this;
      _this.overlay = true;

      let data = {
        PageNumber: _this.currentPage,
        Count: _this.count,
      }

      let productCategoriesGetAll = new ProductCategoriesGetAll(_this);
      productCategoriesGetAll.setParams(data);
      await productCategoriesGetAll.fetch(function (content) {
        _this.overlay = false;
        _this.pageData = content.data.data;
        _this.totalCount = content.data.totalCount;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async addCategory() {
      let _this = this;
      Helper.validateForm(_this, 'refFormObserver', async () => {
        _this.overlay = true;
        let formData = Helper.makeFormData(_this.category);
        let upFile = _this.$refs.refInputEl.files[0];
        let upFile2 = _this.$refs.refInputEl2.files[0];
        formData.append('ProductCategoryFile', upFile);
        formData.append('ProductCategoryItemFile', upFile2);
        let productCategoriesCreate = new ProductCategoriesCreate(_this);
        productCategoriesCreate.setParams(formData);
        await productCategoriesCreate.sendFormData((content) => {
          _this.overlay = false;
          _this.category = {
            parentId: '',
            languageId: null,
            mainId: null,
            name: null,
            color: null,
          };
          _this.base64ImageSrc = null;
          _this.base64ImageSrc2 = null;
          _this.getData();
        }, (error) => {
          _this.overlay = false;
          console.log(error)
        });
      })
    },
    async updateData() {
      let _this = this;
      if (_this.selectedToEdit.name.length < 1) {
        return _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            variant: 'danger',
            text: `Category title is required!`,
          },
        })
      }
      _this.showOverlay = true;
      let formData = Helper.makeFormData(_this.selectedToEdit);
      let productCategoriesUpdate = new ProductCategoriesUpdate(_this);
      productCategoriesUpdate.setParams(formData);
      console.log(formData)
      await productCategoriesUpdate.sendFormData(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Opperation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.showOverlay = false;
        _this.selectedToEdit = {};
        _this.modalCoverPic = null;
        _this.modalCoverPic2 = null;
        _this.getData();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    setCover(e) {
      let file = e.target.files[0];
      this.base64ImageSrc = URL.createObjectURL(file);
    },
    setCover2(e) {
      let file = e.target.files[0];
      this.base64ImageSrc2 = URL.createObjectURL(file);
    },
    setModalCover(e) {
      let file = e.target.files[0];
      this.modalCoverPic = URL.createObjectURL(file);
      this.selectedToEdit.productCategoryFile = file;
    },
    setModalCover2(e) {
      let file = e.target.files[0];
      this.modalCoverPic2 = URL.createObjectURL(file);
      this.selectedToEdit.productCategoryItemFile = file;
    },
    generateObjectForAddEditRemoveButton(data) {
      return {
        id: data.id,
        languageId: data.languageId,
        otherLanguages: data.otherLanguages || [],
        mainId: data.mainId,
      };
    },
    renderDeleteModal(data) {
      this.selectedToDelete = JSON.parse(JSON.stringify(data));
      this.$bvModal.show("modal-delete");
    },
    async renderEditModal(data) {
      if (!data.isOtherLanguage){
        let idx = this.pageData.findIndex(e => e.id === data.id);
        this.selectedToEdit = JSON.parse(JSON.stringify(this.pageData[idx]));
        this.selectedToEdit.parentId = '';
        this.modalCoverPic = this.selectedToEdit.productCategoryFile;
        this.modalCoverPic2 = this.selectedToEdit.productCategoryItemFile;
      }else {
        await this.getCategory(data.id)
      }
      setTimeout(()=>{
        this.$bvModal.show("modal-edit");
      },500)
    },
    renderCreateModal(data) {
      this.category.languageId = data.languageId;
      this.category.mainId = data.mainId;
      this.$bvModal.show("modal-create");
    },
    async getCategory(id){
      let _this = this;
      _this.showOverlay = true;

      let productCategoriesGet = new ProductCategoriesGet(_this);
      productCategoriesGet.setParams({
        id:id,
      })
      await productCategoriesGet.fetch((content) => {
        _this.showOverlay = false;
        _this.selectedToEdit = content.data.data;
        _this.selectedToEdit.parentId = '';
        _this.modalCoverPic = _this.selectedToEdit.productCategoryFile;
        _this.modalCoverPic2 = _this.selectedToEdit.productCategoryItemFile;
      },(e)=>{
        _this.showOverlay = false;
        console.log(e)
      })
    },
    async deleteData() {
      let _this = this;
      _this.overlay = true;

      let productCategoriesDelete = new ProductCategoriesDelete(_this);
      productCategoriesDelete.setParams({id:this.selectedToDelete.id});
      await productCategoriesDelete.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.selectedToDelete = null;
        _this.getData();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: 100,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    clickOnInput(id) {
      let input = document.getElementById(id);
      input.click();
    },
    setLanguageId(language) {
      this.category.languageId = language.languageId;
    },
    closeCreateModal(){
      this.category = {
        parentId: '',
        languageId: null,
        mainId: null,
        name: null,
        color: null,
        ProductCategoryFile: null,
        ProductCategoryItemFile: null
      };
      this.base64ImageSrc = null;
      this.base64ImageSrc2 = null;
    },
    closeEditModal(){
      this.selectedToEdit = {};
      this.modalCoverPic = null;
      this.modalCoverPic2 = null;
    }
  },
  components: {
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>